import React from 'react'
import './ShowroomPrivacyNotice.scss'
import alpLogo from '../../assets/images/brands/alp.webp'
import sideImg from '../../assets/images/premier-circle-bg.webp'

const ShowroomPrivacyNotice = () => {
  return (
    <div className="loyalty-container">
      <div className="loyalty-container__image">
        <div className="alp-logo">
          <img loading="lazy" src={alpLogo} alt="bg" />
        </div>
      </div>
      <div className="loyalty-container__content">
        <section>
          <h2 className="title">Privacy Notice</h2>

          <p>
            Welcome to AyalaLand Premier, Inc.’s (“AyalaLand Premier”) Showroom Request/Feedback Form, an online platform managed by
            AyalaLand Premier (referred to as the terms “we,” “us,” and “our” in this Notice). Here’s how we protect your data privacy:
          </p>
        </section>

        <section>
          <h3>Our Role in Your Data Privacy</h3>

          <article>
            <p>
              When you use the AyalaLand Premier Showroom Request/Feedback Form (https://tally.so/r/3y96pW), this notice applies to you.
              Please read and understand it before providing us with your personal data.
            </p>

            <h4>Our Responsibilities</h4>

            <p>
              We process information pursuant to the Ayala Land Group (“Group”) Privacy Statement. To know more about our general principles
              and how we process your personal data within the Group, please visit https://www.ayalaland.com.ph/privacy-policy/.
            </p>
            <p>
              We may revise this Privacy Notice from time to time as we add new features and purposes of data processing, or as laws change
              that may affect our company’s services. When we make material changes to this Privacy Notice, we will post the date it was
              last revised.
            </p>

            <h4>Your Responsibilities</h4>

            <ul>
              <li>Read and understand this Privacy Notice</li>
              <li>Ensure that any information that you provide us is complete, accurate and up-to-date</li>
              <li>
                If you provide us with personal data about other people, we will use that information only for the specific purpose for
                which it was provided to us. By submitting the information, you warrant and confirm that you have the right to authorize us
                to process it on your behalf in accordance with this Privacy Notice.
              </li>
            </ul>
          </article>
        </section>

        <section>
          <h3>When and How We Collect Data</h3>

          <article>
            <p>
              From the first moment you engage through our showroom request/feedback form, we are collecting data. Here’s when and how we do
              this:
            </p>

            <ul>
              <li>You provide personal information in relation to inquiries, requests, and other concerns.</li>
              <li>
                You provide personal information, whenever you use the <strong>“Showroom Request/Feedback Form”</strong> to lodge a request
                or to report a concern during your visit to an AyalaLand Premier showroom.
              </li>
            </ul>
          </article>
        </section>

        <section>
          <h3>What types of data we collect from you</h3>

          <article>
            <p>
              When you lodge a request or report a concern through our Showroom Request/Feedback Form, the Company collects and processes
              the following personal information that you provide:
            </p>

            <ul>
              <li>Full Name</li>
              <li>Email Address(optional)</li>
              <li>Mobile Phone Number (optional)</li>
              <li>Type of Request/Concern</li>
              <li>
                Any other information that you voluntarily decide to share with AyalaLand Premier in the Request/Concern details field
              </li>
            </ul>

            <h4>Disclosing personal information other than yours</h4>

            <p>
              When you disclose to us personal information of another person (e.g., your dependent, spouse, children, and/or parents), you
              attest that consent has been obtained from that person to disclose and process his/her personal information in accordance with
              this privacy notice.
            </p>

            <p>
              If the Personal Information was provided by a minor or one who is not legally capable of entering into contractual
              transactions without parental or guardian consent, the parent or legal guardian shall be responsible in providing the consent,
              and in correcting any information given or informing us that the information should be disregarded or deleted.
            </p>
          </article>
        </section>

        <section>
          <h3>How and why we use your personal data</h3>

          <article>
            <p>
              Under the Data Privacy Act, we can only use your personal data for certain specified purposes and where we have a legal basis
              to do so. Here are the reasons for which we process your data:
            </p>

            <ul>
              <li>Providing you with product and services that you have requested, including customer support.</li>
              <li>
                Communicating relevant products and services, advisories, and responses to your queries, requests, and other concerns; and
              </li>
              <li>Processing of information for statistical, analytical, and research purposes.</li>
            </ul>
          </article>
        </section>

        <section>
          <h3>Your Privacy Rights</h3>

          <article>
            <p>
              You can exercise your rights as a data subject by sending us an email at{' '}
              <a href="mailto:alpi.dataprivacyoffice@ayalalandpremier.com">alpi.dataprivacyoffice@ayalalandpremier.com</a>.
            </p>

            <h4>You have the right to access information we hold about you</h4>

            <p>
              You may instruct us to provide you with any personal information we hold about you, subject to providing us with appropriate
              evidence of your identity.
            </p>

            <ul>
              <li>We may withhold personal information that you request to the extent permitted by law.</li>
              <li>You may instruct us at any time not to process your personal information for marketing purposes.</li>
              <li>
                You will be asked to expressly agree in advance to our use of your personal information for marketing purposes, or we will
                provide you with an opportunity to opt out of the use of your personal information for the same purpose.
              </li>
            </ul>

            <h4>You can object to us using your data for profiling you or making automated decisions about you</h4>

            <p>
              We may use your data to determine whether we should let you know information that might be relevant to you (for example,
              sending you emails to provide you feedback on your request). Otherwise, the only circumstances in which we will do this is to
              provide service to you.
            </p>

            <h4>You have the right for your data to be erased</h4>

            <p>
              You can do this by asking us to suspend, withdraw, remove or destroy your personal data, if it is no longer necessary for us
              to hold the data for purposes of your use.
            </p>

            <h4>You have the right to lodge a complaint regarding our use of your data</h4>

            <p>
              Please tell us first, so we have a chance to address your concerns. If we fail in this, you can address any complaint to the
              National Privacy Commission.
            </p>

            <h4>You have the right to damages</h4>

            <p>
              You have the right to damages arising from inaccurate, incomplete, false, unlawfully obtained or unauthorized use of your
              personal data by AyalaLand Premier.
            </p>
          </article>
        </section>

        <section>
          <h3>How secure is the data we collect?</h3>

          <p>
            We do our best to ensure that your personal data is protected and your privacy is preserved. We use physical, technical, and
            organizational safeguards to protect your personal data against loss, theft, and unauthorized access, disclosure, copying, use,
            or modification. These practices include:
          </p>

          <ul>
            <li>Use of secured servers behind advanced threat protection appliances, firewalls, encryption and other security tools.</li>

            <li>
              Use of standard encryption tools such as SSL (Secure Socket Layer) to help protect personal data and to keep information
              collected secure.
            </li>

            <li>
              Website/Application security testing conducted by a third party prior to it being launched and when major changes have been
              applied.
            </li>
          </ul>

          <p>
            In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who are
            qualified and authorized to process them. All such persons, wherever they are located, are required to protect the
            confidentiality and privacy of your personal data in a manner consistent with our privacy policies and practices.
          </p>

          <h4>And please remember:</h4>

          <ul>
            <li>You provide personal data at your own risk. Unfortunately, no data transmission is guaranteed to be 100% secure</li>
            <li>
              If you believe your privacy has been breached, please contact us immediately by sending us an email at{' '}
              <a href="mailto:alpi.dataprivacyoffice@ayalalandpremier.com">alpi.dataprivacyoffice@ayalalandpremier.com</a>.
            </li>
          </ul>
        </section>

        <section>
          <h3>How long do we store your data?</h3>

          <article>
            <p>
              We shall retain your personal data obtained via our Showroom Request/Feedback Form for a period of two (2) years from the
              fulfillment of the declared purposes or allowable criteria for its processing; unless we believe that these may be relevant to
              any ongoing or prospective legal proceedings in order to establish, exercise, and defend our legal rights (including providing
              information to others for the purposes of fraud prevention and reducing credit risk).
            </p>

            <p>
              Your personal data shall be disposed or discarded in a secure manner that would prevent further processing, unauthorized
              access, or disclosure to any other party or the public. Specifically, all personal data in electronic form will be deleted
              from our databases, following the retention period stated above.
            </p>
          </article>
        </section>

        <section>
          <h3>Third parties who process your data</h3>
          <h4>Fulfillment of Legitimate and Business Purposes</h4>

          <article>
            <p>
              In fulfillment of the stated purpose/s that you consented to, we may disclose your personal information to the following
              parties:
            </p>

            <ul>
              <li>Our employees and officers;</li>
              <li>Professional advisers such as lawyers and auditors;</li>
              <li>Members of our group of companies or our subsidiaries and affiliates;</li>
              <li>Our holding company Ayala Corporation and all its subsidiaries and affiliates;</li>
              <li>Our joint venture and alliance partners; and</li>
              <li>Our technology and service providers (Tally).</li>
            </ul>
            <p>And in order to comply with legal and statutory compliance, we may disclose your personal data:</p>
            <ul>
              <li>To the extent that we are required to do so by applicable laws, rules, and regulations;</li>
              <li>In connection with any ongoing or prospective legal proceeding;</li>
              <li>
                To any person who we reasonably believe may apply to a court or other competent authority for disclosure of such personal
                information where, in our reasonable opinion, such court or authority would be reasonably and is likely to order the
                disclosure of such personal information;
              </li>
            </ul>

            <p>
              Any company/entity with which we share information about you for the above purposes is contractually required to comply with
              confidentiality standards, undertake to respect any individual’s right to privacy, and comply with the Data Privacy Act. We
              also require that these organizations use this information only for our purposes, and follow our reasonable directions with
              respect to the use and protection of this information. This includes organizations which handle or obtain personal information
              as technical and service providers for Ayala, Land, Inc. who, in addition to the above measures, undergo our due diligence
              that includes their data protection practices.
            </p>
          </article>
        </section>

        <section>
          <h3>Privacy Policies of Third-party Websites and Social Media Platforms</h3>

          <article>
            <p>Our websites contain hyperlinks and details of third-party websites, including our service provider Tally.</p>

            <p>
              We have no control over and are not responsible for the privacy policies and practices of third parties. We therefore advise
              you to study their privacy policies before providing them with any personal information. Once you leave our website, you
              should likewise check the applicable privacy policy of the third-party website to determine how they will handle any
              information they collect from you.
            </p>
          </article>
        </section>

        <section>
          <h3>Cookies and Similar Technologies</h3>

          <article>
            <p>
              We use cookies to provide you with a better and more personal user experience. Unless you adjust your browser settings to
              refuse cookies, we will issue cookies when you interact with AyalaLand Premier. Please note that where third parties use
              cookies, we have no control over how those third parties use those cookies.
            </p>
          </article>
        </section>

        <section>
          <h3>How to Contact Us</h3>

          <article>
            <p>
              For comments, questions, or requests relating to this privacy notice, or complaints in relation to your rights under this
              privacy notice, you may get in touch with the AyalaLand Premier, Inc.’s Data Protection Officer by sending us an email to
              AyalaLand Premier, Inc.’s Compliance Officer for Privacy at{' '}
              <a href="mailto:alpi.dataprivacyoffice@ayalalandpremier.com">alpi.dataprivacyoffice@ayalalandpremier.com</a>.
            </p>
          </article>
        </section>
      </div>
    </div>
  )
}

export default ShowroomPrivacyNotice
